<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ id ? '编辑' : '新增' }}装备包
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="equipInfo" :rules="rules" ref="equipInfo">
                    <!-- <el-form-item label="编号" :label-width="formLabelWidth" prop="id" v-if="state">
                        <el-input v-model="equipInfo.id" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item> -->
                    <el-form-item label="装备包名称" :label-width="formLabelWidth" prop="bagName">
                        <el-input v-model="equipInfo.bagName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="工作内容" :label-width="formLabelWidth" prop="workContent">
                        <el-input v-model="equipInfo.workContent" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="工作日期" :label-width="formLabelWidth" prop="workDate">
                        <el-date-picker v-model="equipInfo.workDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="经办人" :label-width="formLabelWidth" prop="workJbr">
                        <el-input v-model="equipInfo.workJbr" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <h1>所需仪器仪表</h1>
                <el-divider></el-divider>

                <div slot="header" class="deviceForm">
                    <el-form :inline="true">
                        <el-form-item label="仪器分类" class="deviceSelecct">
                            <el-select placeholder="请选择仪器分类" v-model="deviceOneClass">
                                <el-option v-for="(item, index) in deviceClassList" :key="index"
                                    :label="item.deviceOneClassDesc" :value="item.deviceOneClass"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="仪器名称" class="deviceSelecct">
                            <el-select placeholder="请选择仪器名称" v-model="deviceNameCode">
                                <el-option v-for="(item, index) in deviceNameList" :key="index" :label="item.deviceName"
                                    :value="item.deviceNameCode"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="实际名称" class="deviceSelecct">
                            <el-select placeholder="请选择实际名称" v-model="did">
                                <el-option v-for="(item, index) in deviceRealNameList" :key="index"
                                    :label="item.deviceRealName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- <el-form-item class="box_rg"> -->
                            <el-button class="btn" @click="addDeviceInfo">添加</el-button>
                        <!-- </el-form-item> -->

                    </el-form>
                    <!-- <div class="box_rg">
                        <el-button class="btn" @click="addDeviceInfo">添加</el-button>
                    </div> -->
                </div>

                <!-- 仪器列表 -->
                <el-table :data="deviceInfoList" border style="width: 100%; font-size: 13px;"
                    :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                    <el-table-column prop="seq" label="序号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceOneClassDesc" label="仪器分类" align="center"> </el-table-column>
                    <el-table-column prop="deviceName" label="仪器名称" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceRealName" label="实际名称" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceCode" label="仪器条码" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceNum" label="仪器编号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceType" label="仪器型号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceStatus_text" label="仪器状态" align="center">
                    </el-table-column>
                    <el-table-column prop="location" label="保存地点" align="center">
                    </el-table-column>

                    <el-table-column label="操作" align="center" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" title="移除" @click="del(scope.row.id)"
                                class="delColor">移除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="equipForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="equipForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination> -->

                <div class="box_rg">
                    <el-button class="btn" @click="submitForm">提交</el-button>
                </div>
            </div>

        </el-card>
    </div>
</template>

<script>
import { queryEquipBag, addEquipBag, updateEquipBag } from '@/api/deviceEquipbag.js'
import { getDeviceClassList, getDeviceNameList, getDeviceRealNameList, queryDevice, deviceStatusList } from '@/api/device.js'
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            deviceStatusList: deviceStatusList,
            deviceClassList: [],
            deviceNameList: [],
            deviceRealNameList: [],
            deviceInfoList: [],
            equipInfo: {
                id: '',
                bagName: null,
                workContent: null,
                workDate: null,
                workJbr: null,
                deviceContent: null,
                deviceList: [],
                deviceNumber: 0,
                status: '00',
                createTime: null
            },
            deviceOneClass: null,//仪器分类编号
            deviceNameCode: null, //仪器名称编号
            did: null, //仪器编号
            formLabelWidth: "100px",
            rules: {
                bagName: [{ required: true, message: '请输入装备包名称' }],
                workContent: [{ required: true, message: '请输入工作内容' }],
                workDate: [{ required: true, message: '请选择工作日期' }],
                workJbr: [{ required: true, message: '请填写经办人' }],
                status: [{ required: true }]
            },
            submitFlag: false,// 是否提交中,
            state: this.id ? true : false,//判断是新增还是修改
        }
    },
    mounted() {
        this.loadDeviceClassList()
        this.id && this.loadEquipBagInfo()
    },
    methods: {
        async loadEquipBagInfo() {
            await queryEquipBag(this.id).then(res => {
                if (res.code === '000000') {
                    this.equipInfo = res.t
                    this.deviceInfoList = res.t.deviceList
                    let count = 1
                    this.deviceInfoList.forEach(item => {
                        item.seq = count++
                        const index = this.deviceStatusList.findIndex(item2 => item2.code === item.deviceStatus)
                        if (index !== -1) {
                            const { name } = this.deviceStatusList[index]
                            item.deviceStatus_text = name
                        }
                    })
                }
            })
        },
        async loadDeviceClassList() {
            await getDeviceClassList().then(res => {
                this.deviceClassList = res.t
            })
        },
        loadDeviceNameList() {
            if (this.deviceOneClass) {
                getDeviceNameList(this.deviceOneClass).then(res => {
                    if (res.code === '000000') {
                        this.deviceNameList = res.t
                    }
                })
            } else {
                return false
            }

        },
        loadDeviceRealNameList() {
            if (this.deviceNameCode) {
                getDeviceRealNameList(this.deviceNameCode).then(res => {
                    if (res.code === '000000') {
                        this.deviceRealNameList = res.t
                    }
                })
            } else {
                return false
            }

        },

        addDeviceInfo() {
            if (this.did) {
                if (this.deviceInfoList.length > 0) {
                    if (this.deviceInfoList.findIndex(item => item.id === this.did) === -1) {
                        queryDevice(this.did).then(res => {
                            if (res.code === '000000') {
                                const index = this.deviceStatusList.findIndex(item => item.code === res.t.deviceStatus)
                                if (index !== -1) {
                                    const { name } = this.deviceStatusList[index]
                                    res.t.deviceStatus_text = name
                                }
                                this.deviceInfoList.push(res.t)

                            }
                        })
                    } else {
                        this.$message.warning('该仪器已经添加！')
                    }
                } else {
                    queryDevice(this.did).then(res => {
                        if (res.code === '000000') {
                            const index = this.deviceStatusList.findIndex(item => item.code === res.t.deviceStatus)
                            if (index !== -1) {
                                const { name } = this.deviceStatusList[index]
                                res.t.deviceStatus_text = name
                            }
                            this.deviceInfoList.push(res.t)

                        }
                    })
                }
                this.did = null
                this.deviceNameCode = null

            } else {
                return false
            }
        },
        del(id) {
            const index = this.deviceInfoList.findIndex(item => item.id === id)
            if (index !== -1) {
                this.deviceInfoList.splice(index, 1)
            }
        },
        submitForm() {
            this.$refs.equipInfo.validate((valid) => {
                if (valid) {
                    this.submitFlag = true
                    this.equipInfo.deviceList = this.deviceInfoList
                    if (this.id) {
                        updateEquipBag(this.equipInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        addEquipBag(this.equipInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleReset() {
            this.$refs.equipInfo.resetFields()
        },

    },
    watch: {
        deviceOneClass: function () {
            this.loadDeviceNameList()
        },
        deviceNameCode: function () {
            this.loadDeviceRealNameList()
        },
        deviceInfoList: function () {
            let count = 1
            this.deviceInfoList.forEach(item => {
                item.seq = count++
            })
        }
    }

}
</script>
<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 500px;
    }

    .df {
        text-align: right;
    }

    .deviceForm {
        margin-bottom: 10px;
        .deviceSelecct {
            width: 300px;
            margin-right: 20px;
        }

        .box_rg {
            width: 80px;
            // text-align: right;
        }
    }




    .el-table {
        margin-bottom: 30px;
    }
}
</style>
import service from "../service.js";

export const serchEquipBag = params => {
    return service({
      method: 'POST',
      url: '/equipBag/serchEquipBag',
      params
    })
}

export const addEquipBag = (data) => {
    return service({
      method: 'POST',
      url: '/equipBag/addEquipBag',
      data
    })
  }
  
  export const updateEquipBag = (data) => {
    return service({
      method: 'POST',
      url: '/equipBag/updateEquipBag',
      data
    })
  }

  export const delEquipBag = (id) => {
    return service({
      method: 'GET',
      url: '/equipBag/delEquipBag',
      params: {
        id
      }
    })
  }

  export const queryEquipBag = (id) => {
    return service({
      method: 'GET',
      url: '/equipBag/queryEquipBag',
      params: {
        id
      }
    })
  }

  export const ckInventory = (data) => {
    return service({
        method: 'POST',
        url: '/equipBag/ckInventory',
        data
    })
}

  export const deviceStatusList = [{ code: '00', name: '正常' },  { code: '11', name: '装备待维修注意' },  { code: '13', name: '附件待维修注意' }]

  